import React, {useState} from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import LandingPage from "../components/landingpage";
import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
     ... fragSiteSettings
    }
    post: sanityLandingPage(slug: { current: { eq: "home" } }) {
      ... fragLandingPage
      content {
        ... fragSanityCourseTitle
      ... fragSanityAvailableCourses
      ... fragSanityTabsTextboxBlock
      ... fragSanityDropdownWidget
      ... fragSanitySocialMediaPosts
      ... fragSanityTwitterBlock
      ... fragSanityLandingSearchBlock
      ... fragSanityBestBuysTable
      ... fragSanityRecProviders
      ... fragSanityTipChooser
      ... fragSanityBreadcrumb
      ... fragSanityPopularConversationsBlock
      ... fragSanityBigBlockGroup
      ... fragSanityDataCaptureForm
      ... fragSanityHeroSection
      ... fragSanityHeaderBlock
      ... fragSanityTitleSection
      ... fragSanityLatestArticlesBlock
      ... fragSanityCalcReference
      ... fragSanityFilteredQuestionsBlock
      ... fragSanityAdvisersList
      ... fragSanityFundList
      ... fragSmallSpacerBlock
      ... fragWidgetSelection
        ... fragSanityEmailCaptureForm
      }
    }
    dictionary: allSanityDictionary {
      nodes {
       ... dictionaryFragment
      }
    }
   allArticles: allSanityPost (sort: {order: DESC, fields: [publishedAt]}
     limit: 200
     filter: {publishedAt: {ne: null, lt: "2030-01-01"}}
   ){
     nodes {
          ... fragAllArticles
     }
   }

  }
`;

const IndexPage = (props) => {

  const { data, errors } = props;
  const post = data && data.post;
  post.allArticles = data && data.allArticles
  const getPageUrl = "/"

  post.dictionary = data && data.dictionary;

  const [emailCaptured, setEmailCaptured] = useState(false);

  //console.log("data", props)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={toPlainText(post._rawExcerpt)}
        keywords={site.keywords}
        canonical={getPageUrl}
      />

      {post && <LandingPage {...post} emailCaptured={emailCaptured} setEmailCaptured={setEmailCaptured} />}
    </Layout>
  );
};

export default IndexPage;
